<template>
  <div>
    <!-- <nuxt-layout name="layout-one"> -->
    <!-- hero banner start -->
    <hero-banner-one :sliders="sliders" v-if="sliders.length" />
    <!-- hero banner end -->

    <!-- category start -->
    <categories-electronic />
    <!-- category end -->

    <!-- feature area start -->
    <feature-one />
    <!-- feature area end -->

    <!-- product area start -->
    <product-electronics-top-items
      :collections="collections"
      v-if="collections.Trending"
    />
    <!-- product area end -->

    <!-- banner area start -->
    <banner-area :banners="banners.top" v-if="banners.top" />
    <!-- banner area en -->

    <!-- offer product start -->
    <product-electronics-offer-items
      :collections="collections"
      v-if="collections.DealOfTheDay"
    />
    <!-- offer product end -->

    <!-- product gadget area start -->
    <!-- <product-electronics-gadget-items /> -->
    <!-- product gadget area end -->

    <!-- product banner start -->
    <banner-area-2 :banners="banners.slider" v-if="banners.slider" />
    <!-- product banner end -->

    <!-- product new arrivals area start -->
    <product-electronics-new-arrivals
      :collections="collections"
      v-if="collections.NewArrivals"
    />
    <!-- product new arrivals area end -->

    <!-- product sm items start  -->
    <product-electronics-sm-items
      :collections="collections"
      v-if="collections.Others"
    />
    <!-- product sm items end -->

    <!-- blog item start -->
    <!-- <blog-electronic /> -->
    <!-- blog item end -->

    <!-- instagram area start -->
    <!-- <instagram-area-1 /> -->
    <!-- instagram area end -->

    <!-- subscribe area start -->
    <subscribe-1 />
    <!-- subscribe area end -->
    <!-- </nuxt-layout> -->
  </div>
</template>

<script setup lang="ts">
const banners = ref({});
const sliders = ref([]);
const collections = ref({});
const getBanners = async () => {
  await fetch("/home-banners", { cache: "force-cache" }).then((res) => {
    banners.value = res.banner;
  });
};
const getSliders = async () => {
  await fetch("/sliders", { cache: "force-cache" }).then((res) => {
    sliders.value = res.data;
  });
};
const getCollections = async () => {
  await fetch("/home-collections", { cache: "force-cache" }).then((res) => {
    collections.value = res.collections;
  });
};
const { locale } = useI18n();

await Promise.all([getBanners(), getSliders(), getCollections()]);
useSeoMeta({
  title: "Jumbo tech",
  description: "Jumbo tech",
  ogTitle: "Jumbo tech",
  ogDescription: "Jumbo tech",
  ogImage: "/img/logo/logo.png",
});
</script>
