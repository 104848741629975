<template>
  <svg
    width="52"
    height="13"
    viewBox="0 0 52 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.97127C11.6061 -5.48521 33 3.99996 51 11.4635"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="3.8637"
      stroke-linecap="round"
    />
  </svg>
</template>

<script setup lang="ts"></script>
