<template>
  <section class="tp-banner-area pb-70" v-if="props.banners">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-7">
          <div
            class="tp-banner-item tp-banner-height p-relative mb-30 z-index-1 fix"
          >
            <div
              class="tp-banner-thumb include-bg transition-3"
              :style="`background-image: url(${firstBanner.default_img});`"
            ></div>
            <div class="tp-banner-content">
              <span>{{ firstBanner.sub_title }}</span>
              <h3 class="tp-banner-title">
                <nuxt-link-locale :href="firstBanner.link">{{
                  firstBanner.title
                }}</nuxt-link-locale>
              </h3>
              <div class="tp-banner-btn">
                <nuxt-link-locale :href="firstBanner.link" class="tp-link-btn"
                  >{{ firstBanner.button }}
                  <SvgRightArrow2 />
                </nuxt-link-locale>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-5">
          <div
            class="tp-banner-item tp-banner-item-sm tp-banner-height p-relative mb-30 z-index-1 fix"
          >
            <div
              class="tp-banner-thumb include-bg transition-3"
              :style="`background-image: url(${secondBanner.default_img});`"
            ></div>
            <div class="tp-banner-content">
              <h3 class="tp-banner-title">
                <nuxt-link-locale :href="secondBanner.link">{{
                  secondBanner.title
                }}</nuxt-link-locale>
              </h3>
              <!-- <p>Sale 35% off</p> -->
              <div class="tp-banner-btn">
                <nuxt-link-locale :href="secondBanner.link" class="tp-link-btn"
                  >{{ secondBanner.button }}
                  <SvgRightArrow2 />
                </nuxt-link-locale>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
const props = defineProps({
  banners: {
    type: Object,
    required: true,
  },
});
const firstBanner = computed(() => {
  return props.banners[0];
});
const secondBanner = computed(() => {
  return props.banners[1];
});
</script>
