<template>
  <section class="tp-product-arrival-area pb-55">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-xl-5 col-sm-6">
          <div class="tp-section-title-wrapper mb-40">
            <h3 class="tp-section-title">
              {{ $t("New Arrivals") }}
              <SvgSectionLine />
            </h3>
          </div>
        </div>
        <div class="col-xl-7 col-sm-6">
          <div
            class="tp-product-arrival-more-wrapper d-flex justify-content-end"
          >
            <div
              class="tp-product-arrival-arrow tp-swiper-arrow mb-40 text-end tp-product-arrival-border"
            >
              <button
                type="button"
                class="tp-arrival-slider-button-prev me-2"
                aria-label="prev"
              >
                <SvgPrevArrow />
              </button>
              <button
                type="button"
                class="tp-arrival-slider-button-next"
                aria-label="next"
              >
                <SvgNextArrow />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="tp-product-arrival-slider fix">
            <Swiper
              :dir="currentLanguage"
              :slidesPerView="4"
              :spaceBetween="30"
              :pagination="{
                el: '.tp-arrival-slider-dot',
                clickable: true,
              }"
              :navigation="{
                nextEl: '.tp-arrival-slider-button-next',
                prevEl: '.tp-arrival-slider-button-prev',
              }"
              :breakpoints="{
                '1200': {
                  slidesPerView: 4,
                },
                '992': {
                  slidesPerView: 3,
                },
                '768': {
                  slidesPerView: 2,
                },
                '576': {
                  slidesPerView: 2,
                },
                '0': {
                  slidesPerView: 2,
                },
              }"
              :modules="[Pagination, Navigation]"
              class="tp-product-arrival-active swiper-container"
            >
              <SwiperSlide v-for="(item, i) in new_arrivals" :key="i">
                <ProductElectronicsItem :item="item" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";

import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
import { useUtilityStore } from "../../../pinia/useUtilityStore";
// const { collections } = storeToRefs(useHomeStore());
const props = defineProps({
  collections: {
    type: Object,
    required: true,
  },
});
const new_arrivals = computed(() => {
  return props.collections?.NewArrivals?.[0]?.products;
});
const { currentLanguage } = storeToRefs(useUtilityStore());
const sliderDirection = computed(() => {
  return currentLanguage.value.code === "ar" ? "rtl" : "";
});
</script>
