<template>
  <section class="tp-feature-area tp-feature-border-radius pb-70">
    <div class="container">
      <div class="row gx-1 gy-1 gy-xl-0">
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div class="tp-feature-item d-flex align-items-start">
            <div class="tp-feature-icon mr-15">
              <span>
                <svg-delivery />
              </span>
            </div>
            <div class="tp-feature-content">
              <h3 class="tp-feature-title">{{ $t("Free Delivary") }}</h3>
              <p>{{ $t("Orders from all item") }}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div class="tp-feature-item d-flex align-items-start">
            <div class="tp-feature-icon mr-15">
              <span>
                <svg-refund />
              </span>
            </div>
            <div class="tp-feature-content">
              <h3 class="tp-feature-title">{{ $t("Return & Refunf") }}</h3>
              <p>{{ $t("Maney back guarantee") }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
          <div class="tp-feature-item d-flex align-items-start">
            <div class="tp-feature-icon mr-15">
              <span>
                <svg-discount />
              </span>
            </div>
            <div class="tp-feature-content">
              <h3 class="tp-feature-title">Member Discount</h3>
              <p>One very order over $140.00</p>
            </div>
          </div>
        </div> -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div class="tp-feature-item d-flex align-items-start">
            <div class="tp-feature-icon mr-15">
              <span>
                <svg-support />
              </span>
            </div>
            <div class="tp-feature-content">
              <h3 class="tp-feature-title">{{ $t("Support 24/7") }}</h3>
              <p>{{ $t("Contact us 24 hours a day") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>
