<template>
  <svg
    width="64"
    height="20"
    viewBox="0 0 64 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62 13.1107C1.91792 -5.41202 -3.10865 1.22305 5.00242 18.3636"
      stroke="currentColor"
      stroke-width="3"
      stroke-miterlimit="3.8637"
      stroke-linecap="round"
    />
  </svg>
</template>

