<template>
  <section class="tp-product-offer grey-bg-2 pt-70 pb-80">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-xl-4 col-md-5 col-sm-6">
          <div class="tp-section-title-wrapper mb-40">
            <h3 class="tp-section-title">
              {{ $t("Deal of The Day") }}
              <SvgSectionLine />
            </h3>
          </div>
        </div>
        <div class="col-xl-8 col-md-7 col-sm-6">
          <div
            class="tp-product-offer-more-wrapper d-flex justify-content-sm-end p-relative z-index-1"
          >
            <div class="tp-product-offer-more mb-40 text-sm-end grey-bg-2">
              <nuxt-link-locale
                href="/shop"
                class="tp-btn tp-btn-2 tp-btn-blue"
              >
                {{ $t("View All Deals") }}
                <SvgRightArrow />
              </nuxt-link-locale>
              <span class="tp-product-offer-more-border"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="tp-product-offer-slider fix">
            <Swiper
              :dir="sliderDirection"
              :slidesPerView="4"
              :spaceBetween="30"
              :pagination="{
                el: '.tp-deals-slider-dot',
                clickable: true,
              }"
              :breakpoints="{
                '1200': {
                  slidesPerView: 3,
                },
                '992': {
                  slidesPerView: 2,
                },
                '768': {
                  slidesPerView: 2,
                },
                '576': {
                  slidesPerView: 2,
                },
                '0': {
                  slidesPerView: 2,
                },
              }"
              :modules="[Pagination]"
              class="tp-product-offer-slider-active swiper-container"
            >
              <SwiperSlide v-for="(item, i) in offer_products" :key="i">
                <ProductElectronicsItem :item="item" :offer_style="true" />
              </SwiperSlide>
              <div
                class="tp-deals-slider-dot tp-swiper-dot text-center mt-40"
              ></div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
import { useUtilityStore } from "../../../pinia/useUtilityStore";
// const { collections } = storeToRefs(useHomeStore());
const props = defineProps({
  collections: {
    type: Object,
    required: true,
  },
});
const offer_products = computed(() => {
  return props.collections?.DealOfTheDay?.[0]?.products;
});
const { currentLanguage } = storeToRefs(useUtilityStore());
const sliderDirection = computed(() => {
  return currentLanguage.value.code === "ar" ? "rtl" : "";
});
</script>
