<template>
  <div class="tp-product-banner-area pb-90">
    <div class="container">
      <div class="tp-product-banner-slider fix" v-if="props.banners">
        <Swiper
          :slidesPerView="1"
          :spaceBetween="0"
          :effect="'fade'"
          :pagination="{
            el: '.tp-product-banner-slider-dot',
            clickable: true,
          }"
          :modules="[EffectFade, Pagination]"
          class="tp-product-banner-slider-active swiper-container"
        >
          <SwiperSlide
            v-for="(item, i) in props.banners"
            :key="i"
            class="tp-product-banner-inner theme-bg p-relative z-index-1 fix"
          >
            <!-- <h4 class="tp-product-banner-bg-text">{{item.banner_bg_txt}}</h4> -->
            <div class="row align-items-center">
              <div class="col-xl-6 col-lg-6">
                <div class="tp-product-banner-content p-relative z-index-1">
                  <span class="tp-product-banner-subtitle">
                    {{ item.sub_title }}
                  </span>
                  <h3 class="tp-product-banner-title">
                    {{ item.title }}
                  </h3>
                  <div class="tp-product-banner-price mb-40">
                    {{ item.text }}
                  </div>
                  <div class="tp-product-banner-btn">
                    <nuxt-link-locale :href="item.link" class="tp-btn tp-btn-2">
                      {{ item.button }}</nuxt-link-locale
                    >
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="tp-product-banner-thumb-wrapper p-relative">
                  <!-- <div class="tp-product-banner-thumb-shape">
                    <span class="tp-product-banner-thumb-gradient"></span>
                    <img
                      class="tp-offer-shape"
                      src="/img/banner/banner-slider-offer.png"
                      alt="offer-shape"
                    />
                  </div> -->

                  <div
                    class="tp-product-banner-thumb text-end p-relative z-index-1"
                  >
                    <NuxtImg
                      format="webp"
                      loading="lazy"
                      :src="item.default_img"
                      alt="slider-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <div class="tp-product-banner-slider-dot tp-swiper-dot"></div>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade, Pagination } from "swiper/modules";
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
import { useLinkFormatter } from "@/composables/useLinkFormatter";
const { banners } = storeToRefs(useHomeStore());
const props = defineProps({
  banners: {
    type: Object,
    required: true,
  },
});
// banner data type
</script>
