<template>
  <section class="tp-product-area pb-55">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-xl-5 col-lg-6 col-md-5">
          <div class="tp-section-title-wrapper mb-40">
            <h3 class="tp-section-title">
              {{ $t("Trending Products") }}
              <SvgSectionLine />
            </h3>
          </div>
        </div>
        <div class="col-xl-7 col-lg-6 col-md-7">
          <div
            class="tp-product-tab tp-product-tab-border mb-45 tp-tab d-flex justify-content-md-end"
          >
            <ul class="nav nav-tabs justify-content-sm-end" id="productTab">
              <li
                v-for="(tab, i) in props.collections.Trending"
                :key="i"
                class="nav-item"
              >
                <button
                  @click="handleActiveTab(tab.id)"
                  :class="`nav-link ${active_tab === tab.id ? 'active' : ''}`"
                >
                  {{ tab.name }}
                  <span class="tp-product-tab-line">
                    <SvgActiveLine />
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="tp-product-tab-content">
            <div class="row">
              <div
                v-for="(item, i) in filteredProducts"
                :key="i"
                class="col-6 col-xl-3 col-lg-3"
              >
                <ProductElectronicsItem :item="item" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
// const { collections } = storeToRefs(useHomeStore());

const active_tab = ref(1);
const props = defineProps({
  collections: {
    type: Object,
    required: true,
  },
});
const tabs = ["New", "Featured", "Top Sellers"];
// handleActiveTab
const handleActiveTab = (tab: string) => {
  active_tab.value = tab;
};
const firstCollectionID = computed(() => {
  return props.collections?.Trending?.[0]?.id ?? 1;
});
active_tab.value = firstCollectionID.value;
const filteredProducts = computed(() => {
  return props.collections?.Trending?.filter(
    (collection) => collection.id === active_tab.value
  )?.[0]?.products;
});
</script>
