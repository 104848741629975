<template>
  <section class="tp-product-category pt-60 pb-15">
    <div class="container">
      <div class="row row-cols-xl-5 row-cols-lg-5 row-cols-md-4">
        <div v-for="(item, i) in categories" :key="i" class="col">
          <div class="tp-product-category-item text-center mb-40">
            <div class="tp-product-category-thumb fix">
              <nuxt-link-locale
                class="cursor-pointer"
                :to="`/category/${item.slug}`"
              >
                <NuxtImg
                  :src="item.default_img"
                  alt="product-category"
                  class="mx-w-100"
                  loading="lazy"
                />
              </nuxt-link-locale>
            </div>
            <div class="tp-product-category-content">
              <h3 class="tp-product-category-title">
                <nuxt-link-locale
                  class="cursor-pointer"
                  :to="`/category/${item.slug}`"
                >
                  {{ item.name }}
                </nuxt-link-locale>
              </h3>
              <!-- <p>{{ item.products.length }} Product</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
const { categories } = storeToRefs(useHomeStore());

const router = useRouter();
const localePath = useLocalePath();

// handle parent
</script>
<style>
.mx-w-100 {
  max-width: 80%;
}
</style>
