<template>
  <section class="tp-subscribe-area pt-70 pb-65 theme-bg p-relative z-index-1">
    <div class="tp-subscribe-shape">
      <NuxtImg
        loading="lazy"
        format="webp"
        class="tp-subscribe-shape-1"
        src="/img/subscribe/subscribe-shape-1.png"
        alt="shape"
      />
      <NuxtImg
        loading="lazy"
        format="webp"
        class="tp-subscribe-shape-2"
        src="/img/subscribe/subscribe-shape-2.png"
        alt="shape"
      />
      <NuxtImg
        loading="lazy"
        format="webp"
        class="tp-subscribe-shape-3"
        src="/img/subscribe/subscribe-shape-3.png"
        alt="shape"
      />
      <NuxtImg
        loading="lazy"
        format="webp"
        class="tp-subscribe-shape-4"
        src="/img/subscribe/subscribe-shape-4.png"
        alt="shape"
      />

      <div class="tp-subscribe-plane">
        <NuxtImg
          loading="lazy"
          format="webp"
          class="tp-subscribe-plane-shape"
          src="/img/subscribe/plane.png"
          alt="plane"
        />
        <SvgAnimatedLine />
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-7 col-lg-7">
          <div class="tp-subscribe-content">
            <span>{{ $t("Sale 20% off all store") }}</span>
            <h3 class="tp-subscribe-title">
              {{ $t("Subscribe our Newsletter") }}
            </h3>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5">
          <div class="tp-subscribe-form">
            <form @submit="onSubmit">
              <div class="tp-subscribe-input">
                <input
                  type="email"
                  v-bind="email"
                  placeholder="Enter Your Email"
                />
                <button type="submit">{{ $t("Subscribe") }}</button>
                <err-message :msg="errors.email" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useForm, Field } from "vee-validate";
import * as yup from "yup";
import { useHomeStore } from "../../pinia/useHomeStore";
import { toast } from "vue3-toastify";

interface IFormValues {
  email?: string | null;
}
const { errors, handleSubmit, defineInputBinds, resetForm } =
  useForm<IFormValues>({
    validationSchema: yup.object({
      email: yup.string().required().email().label("Email"),
    }),
  });
const { subscribeNewsletters } = useHomeStore();
const onSubmit = handleSubmit(async (values) => {
  await subscribeNewsletters(values.email)
    .then((res) => {
      toast.success(res.message);
    })
    .finally(() => {
      resetForm();
    });
});

const email = defineInputBinds("email");
</script>
