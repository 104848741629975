<template>
  <!-- offer_style ? 'tp-product-offer-item' : 'mb-25'} -->
  <div class="tp-product-item transition-3">
    <div class="tp-product-thumb p-relative fix m-img">
      <nuxt-link-locale :href="`/product-details/${item.slug}`">
        <NuxtImg
          :src="item.main_img?.full_url"
          alt="product-electronic"
          loading="lazy"
          format="webp"
        />
      </nuxt-link-locale>

      <!-- product badge -->
      <div class="tp-product-badge">
        <span v-if="item.current_stock == 0" class="product-hot">{{
          $t("out-of-stock")
        }}</span>
      </div>

      <!-- product action -->
      <div class="tp-product-action">
        <div class="tp-product-action-item d-flex flex-column">
          <button
            type="button"
            class="tp-product-action-btn tp-product-quick-view-btn"
            data-bs-toggle="modal"
            :data-bs-target="`#${utilityStore.modalId}`"
            @click="
              utilityStore.handleOpenModal(`product-modal-${item.id}`, item)
            "
          >
            <img src="/img/product/add_cart.svg" width="20" height="20" />
            <!-- <svg-add-cart /> -->
            <span class="tp-product-tooltip">{{ $t("Add to Cart") }}</span>
          </button>
          <button
            @click="wishlistStore.add_wishlist_product(item)"
            type="button"
            :class="`tp-product-action-btn tp-product-add-to-wishlist-btn ${
              isItemInWishlist(item) ? 'active' : ''
            }`"
          >
            <img src="/img/product/wishlist.svg" width="20" height="20" />
            <!-- <svg-wishlist /> -->
            <span class="tp-product-tooltip">
              {{
                isItemInWishlist(item)
                  ? "Remove From Wishlist"
                  : "Add To Wishlist"
              }}
            </span>
          </button>
        </div>
      </div>
    </div>
    <!-- product content -->
    <div class="tp-product-content">
      <div class="tp-product-category">
        <nuxt-link-locale :href="`/brand/${item.brand_details.slug}`">{{
          item.brand_details.name
        }}</nuxt-link-locale>
      </div>
      <h3 class="tp-product-title">
        <nuxt-link-locale :href="`/product-details/${item.slug}`">
          {{ item.name }}
        </nuxt-link-locale>
      </h3>
      <!--  <div class="tp-product-rating d-flex align-items-center">
        <div class="tp-product-rating-icon">
          <span v-for="rating in item.rating ?? 0" :key="rating"
            ><i class="fa-solid fa-star"></i
          ></span>
        </div>
       <div class="tp-product-rating-text">
          <span>({{ item.reviews }} {{ $t("Review") }})</span>
        </div>
      </div> -->
      <div class="tp-product-price-wrapper">
        <div v-if="item.old_price">
          <span class="tp-product-price old-price">{{
            formatPrice(item.old_price.toFixed(2))
          }}</span>
          <span class="tp-product-price new-price">
            {{ formatPrice(item.price.toFixed(2)) }}
            <!-- ${{
              (
                Number(item.price) -
                (Number(item.price) * Number(item.discount)) / 100
              ).toFixed(2)
            }} -->
          </span>
        </div>
        <span v-else class="tp-product-price new-price">{{
          formatPrice(item.price.toFixed(2))
        }}</span>
      </div>

      <!-- <div class="tp-product-countdown" v-if="offer_style">
        <div class="tp-product-countdown-inner">
          <ul>
            <li>
              <span>{{ timer.days }}</span> Day
            </li>
            <li>
              <span>{{ timer.hours }}</span> Hrs
            </li>
            <li>
              <span>{{ timer.minutes }}</span> Min
            </li>
            <li>
              <span>{{ timer.seconds }}</span> Sec
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from "@/pinia/useCartStore";
import { useWishlistStore } from "@/pinia/useWishlistStore";
import { useUtilityStore } from "@/pinia/useUtilityStore";
import { type IProduct } from "@/types/product-type";
import { useTimer, type UseTimer } from "vue-timer-hook";
import { useCurrencyStore } from "../../../pinia/useCurrencyStore";

const props = defineProps<{ item: IProduct; offer_style?: boolean }>();
const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const utilityStore = useUtilityStore();
const { formatPrice } = useCurrencyStore();
function isItemInWishlist(product: IProduct) {
  return wishlistStore.wishlists.some((prd) => prd.id === product.id);
}
function isItemInCart(product: IProduct) {
  return cartStore.cart_products.some((prd) => prd.id === product.id);
}

let timer: UseTimer;
if (props.item.offerDate) {
  const endTime = new Date(props.item.offerDate.endDate);
  const endTimeMs = endTime.getTime();
  timer = useTimer(endTimeMs);
}
</script>
