<template>
  <section
    class="tp-slider-area p-relative z-index-1"
    v-if="props.sliders.length"
  >
    <Swiper
      :dir="sliderDirection"
      :slidesPerView="1"
      :spaceBetween="30"
      :loop="false"
      :navigation="{
        nextEl: '.tp-slider-button-next',
        prevEl: '.tp-slider-button-prev',
      }"
      :pagination="{
        el: '.tp-slider-dot',
        clickable: true,
      }"
      :effect="'fade'"
      :modules="[Navigation, Pagination, EffectFade]"
      :onSlideChange="(swiper) => handleActiveIndex(swiper.activeIndex)"
      :class="`tp-slider-active tp-slider-variation swiper-container ${
        isActive ? 'is-light' : ''
      }`"
    >
      <SwiperSlide
        v-for="(item, i) in props.sliders"
        :key="i"
        :class="`tp-slider-item tp-slider-height d-flex align-items-center green-dark-bg`"
      >
        <div class="tp-slider-shape">
          <NuxtImg
            class="tp-slider-shape-1"
            src="/img/slider/shape/slider-shape-1.webp"
            alt="slider-shape"
            width="200"
          />
          <NuxtImg
            format="webp"
            loading="lazy"
            class="tp-slider-shape-2"
            src="/img/slider/shape/slider-shape-2.png"
            alt="slider-shape"
          />
          <NuxtImg
            format="webp"
            loading="lazy"
            class="tp-slider-shape-3"
            src="/img/slider/shape/slider-shape-3.png"
            alt="slider-shape"
          />
          <NuxtImg
            format="webp"
            loading="lazy"
            class="tp-slider-shape-4"
            src="/img/slider/shape/slider-shape-4.png"
            alt="slider-shape"
          />
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-5 col-lg-6 col-md-6">
              <div class="tp-slider-content p-relative z-index-1">
                <span>{{ item.sub_title }}</span>
                <h3 class="tp-slider-title">{{ item.title }}</h3>
                <p>
                  {{ item.text }}
                </p>

                <div class="tp-slider-btn">
                  <nuxt-link-locale
                    :href="item.link"
                    class="tp-btn tp-btn-2 tp-btn-white"
                  >
                    {{ item.button }}
                    <SvgRightArrow />
                  </nuxt-link-locale>
                </div>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-6">
              <div class="tp-slider-thumb text-end">
                <NuxtImg
                  width="400"
                  :src="item.default_img"
                  alt="slider-img"
                  densities="x1 x2 x3"
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <div class="tp-slider-arrow tp-swiper-arrow d-none d-lg-block">
        <button type="button" class="tp-slider-button-prev">
          <SvgPrevArrow />
        </button>
        <button type="button" class="tp-slider-button-next">
          <SvgNextArrow />
        </button>
      </div>
      <div class="tp-slider-dot tp-swiper-dot"></div>
    </Swiper>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { useHomeStore } from "@/pinia/useHomeStore";
import { storeToRefs } from "pinia";
import { useLinkFormatter } from "@/composables/useLinkFormatter";
import { useUtilityStore } from "../../pinia/useUtilityStore";

// const { sliders } = storeToRefs(useHomeStore());
const props = defineProps({
  sliders: {
    type: Array,
    required: true,
  },
});
let isActive = ref<boolean>(false);
const handleActiveIndex = (index: number) => {
  console.log(index);
  if (index === 2) {
    isActive.value = true;
  } else {
    isActive.value = false;
  }
};
const { currentLanguage } = storeToRefs(useUtilityStore());
const sliderDirection = computed(() => {
  return currentLanguage.value.code === "ar" ? "rtl" : "";
});
</script>
<style>
.tp-slider-content,
.tp-slider-content > span,
.tp-slider-content p {
  color: #000;
}

.tp-slider-active .tp-slider-dot.tp-swiper-dot .swiper-pagination-bullet {
  background: rgba(1, 15, 28, 0.2);
}
.tp-slider-active
  .tp-slider-dot.tp-swiper-dot
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--tp-theme-primary);
}
</style>
