<template>
  <div class="tp-product-sm-item d-flex align-items-center">
    <div class="tp-product-thumb mr-25 fix">
      <nuxt-link-locale :href="`/product-details/${item.slug}`">
        <NuxtImg
          loading="lazy"
          :src="item.main_img?.full_url"
          alt="product-img"
          width="100"
          height="100"
        />
      </nuxt-link-locale>
    </div>
    <div class="tp-product-sm-content">
      <div class="tp-product-category mb-2">
        <nuxt-link-locale :href="`/brand/${item.brand_details.slug}`">{{
          item.brand_details.name
        }}</nuxt-link-locale>
      </div>
      <h3 class="tp-product-title">
        <nuxt-link-locale :href="`/product-details/${item.slug}`">{{
          item.name
        }}</nuxt-link-locale>
      </h3>
      <!--  <div class="tp-product-rating d-sm-flex align-items-center">
        <div class="tp-product-rating-icon">
          <span v-for="rating in item.rating ?? 0" :key="rating"
            ><i class="fa-solid fa-star"></i
          ></span>
        </div>
      <div class="tp-product-rating-text">
          <span>({{ item.reviews }} {{ $t("Review") }})</span>
        </div> 
      </div>-->
      <div class="tp-product-price-wrapper">
        <span v-if="item.old_price" class="tp-product-price">
          <!-- ${{(Number(item.price) - (Number(item.price) * Number(item.discount)) / 100).toFixed(2)}} -->
          {{ formatPrice(item.price.toFixed(2)) }}
        </span>
        <span v-else class="tp-product-price">{{
          formatPrice(item.price.toFixed(2))
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type IProduct } from "@/types/product-type";
import { useCurrencyStore } from "../../../pinia/useCurrencyStore";

const props = defineProps<{ item: IProduct }>();
const { formatPrice } = useCurrencyStore();
</script>
